<template>
  <div class="nav-footer">
    <div class="relative">
      <img class="absolute -bottom-12 left-0" :src="group309" />
    </div>

    <div class="footerGradient relative z-10">
      <img class="absolute bottom-0 left-0" :src="ellipse28" />
      <div class="relative nav-footer-content z-10 px-2">
        <div class="flex pt-5">
          <img :src="icareLogo" />
        </div>
        <div class="grid sm:grid-cols-3 gap-0 sm:gap-5 mt-1.5">
          <div class="cols-span-1">
            <div class="footer-sublabel">Merupakan</div>
            <div class="font-bold font-Roboto text-white">Mobile Ticketing App</div>
            <div class="footer-sublabel">Untuk menangani keluhan <b>customer</b></div>
            <div class="sm:mt-2 xs:mt-0.5">
              <a
                class="btn btn-ghost p-0 hover:border-transparent"
                href="http://play.google.com/store/apps/details?id=com.sabaindomedika.stscustomer"
                style="height: 20px"
              >
                <img class="w-full h-full" :src="gpIcon" style="height: 20px" />
              </a>
            </div>
          </div>
          <div class="justify-self-end place-self-center sm:col-span-2 hidden sm:block">
            <div class="flex space-x-4">
              <img :src="saba" />
              <img :src="sysmex" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import group309 from '@/assets/images/group309.png'
import ellipse28 from '@/assets/images/ellipse28.png'
import icareLogo from '@/assets/images/tagline.png'
import gpIcon from '@/assets/images/google_play.png'
import saba from '@/assets/images/saba.png'
import sysmex from '@/assets/images/sysmex.png'

export default {
  name: 'footer-default',
  data() {
    return {
      group309,
      ellipse28,
      icareLogo,
      gpIcon,
      saba,
      sysmex
    }
  }
}
</script>
